"use client"

import useToast from "@/lib/useToast"
import { captureException } from "@sentry/nextjs"
import Button from "components/Button"
import Container from "components/Container"
import Image from "components/Image"
import { isNotFoundError } from "next/dist/client/components/not-found"
import { Fragment, ReactNode, useEffect, useRef } from "react"
import { H5, SerifH5, XSMed } from "styles/Type"

const QUOTES = [
  {
    quote: `Now you're lost
    Lost in the heat of it all
    Girl you know you're lost
    Lost in the thrill of it all`,
    cite: "Lost - Frank Ocean",
  },
]

export default function ErrorPage({
  children,
  error,
}: {
  children: ReactNode
  error?: any
}) {
  const { quote, cite } = QUOTES[0]

  const reported = useRef(false)
  const { addToast } = useToast()

  useEffect(() => {
    if (reported.current || !error) {
      return
    }
    if (isNotFoundError(error)) {
      return
    }
    addToast(error, "error")
    captureException(error)
    reported.current = true
  }, [addToast, error])
  return (
    <div className="min-h-screen flex items-center relative text-center overflow-hidden pb-40 lg:pb-0">
      <Container className="w-full">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:relative">
          <div>
            <H5
              as="h1"
              className="text-medgray origin-left absolute uppercase hidden lg:block"
              style={{
                transform: "rotate(-90deg) translateX(-50%) translateY(100%)",
              }}
            >
              500 Error
            </H5>
            <H5 className="text-medgray uppercase lg:hidden mb-24 relative z-10">
              Internal Server Error
            </H5>
          </div>
          <div>
            <figure className="absolute inset-0 lg:bottom-auto lg:right-auto lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 opacity-20 lg:w-[26vh]">
              <div
                className="w-full lg:relative lg:clip-ellipse grayscale"
                style={{
                  paddingBottom: "217%",
                }}
              >
                <Image
                  alt=""
                  src="/assets/images/eoi_5.jpg"
                  className="absolute inset-0 w-full h-full"
                  sizes="(min-width: 1024px) 26vh, 100vw"
                  fill
                />
              </div>
            </figure>
            <div
              className="font-serif leading-[1] font-thin tracking-[-0.1em] lg:relative text-[75vw] sm:text-[30rem] lg:text-[70vh] absolute -bottom-10 left-1/2 -translate-x-1/2 lg:transform-none lg:left-auto lg:ml-[-0.1em] ml-[-0.05em] mb-[-0.24em] lg:mb-[-0.1em]"
              style={{
                padding: "2vw 0",
                paddingLeft: "4%",
              }}
            >
              500
            </div>
            <figure className="relative lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:px-3 lg:w-[22vh]">
              <SerifH5 as="blockquote" className="mb-3">
                {quote.split("\n").map((q, idx, arr) =>
                  idx + 1 === arr.length ? (
                    q
                  ) : (
                    <Fragment key={idx}>
                      {q}
                      <br />
                    </Fragment>
                  )
                )}
              </SerifH5>
              <XSMed as="cite" className="block not-italic">
                {cite}
              </XSMed>
            </figure>
          </div>
          <div>
            <H5
              as="p"
              className="text-medgray origin-left absolute uppercase whitespace-nowrap hidden lg:block"
              style={{
                transform: "rotate(-90deg) translateX(-50%) translateY(-100%)",
              }}
            >
              Internal Server Error
            </H5>
          </div>
        </div>
        {children || (
          <Button href="/" theme="ghost" className="mt-16">
            Oh, take me home
          </Button>
        )}
      </Container>
    </div>
  )
}
